import React from "react";
import Typography from "@material-ui/core/Typography";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Title from "../components/Title";
import Card from "../components/Card";
import Seo from "../components/Seo";

const Formations = ({
  data: {
    allContentfulFormation: { nodes: formations },
  },
}) => {
  let aujourdhui = new Date();
  aujourdhui = Date.parse(aujourdhui);
  let date;
  return (
    <>
      <Seo title="Formations" />
      <Layout>
        <section className="formations-page">
          <section className="section">
            <div>
              <Typography
                component="h1"
                align="center"
                variant="h3"
                style={{ marginTop: 40, marginBottom: 40 }}
              >
                Formations En Cours
              </Typography>

              <div className="section-center services-center">
                {formations.map((formation) => {
                  const image = formation.image
                    ? formation.image.fixed
                    : undefined;

                  for (let i = 0; i < formation.info.dates.length; i++) {
                    if (
                      aujourdhui <
                      Date.parse(formation.info.dates[i].dateDeDebut)
                    ) {
                      date = formation.info.dates[i].jourDeFormation[0];
                      break;
                    } else {
                      date = "Prochainement";
                    }
                  }

                  if (date !== "Prochainement") {
                    return (
                      <>
                        <Card
                          slug={formation.slug}
                          id={formation.id}
                          title={formation.title}
                          subTitle={formation.subTitle}
                          price={formation.info.price}
                          description={formation.description.description}
                          smallDescription={
                            formation.smallDescription.smallDescription
                          }
                          date={date}
                          key={formation.id}
                          image={image}
                          backgroundColor={
                            formation.color && formation.color.primary
                              ? formation.color.primary
                              : "#333"
                          }
                        />
                      </>
                    );
                  }
                })}
              </div>
            </div>
            <div>
              <Typography
                component="h2"
                align="center"
                variant="h3"
                style={{ marginTop: 20, marginBottom: 40 }}
              >
                Prochainement
              </Typography>

              <div className="section-center services-center">
                {formations.map((formation) => {
                  const image = formation.image
                    ? formation.image.fixed
                    : undefined;

                  for (let i = 0; i < formation.info.dates.length; i++) {
                    if (
                      aujourdhui <
                      Date.parse(formation.info.dates[i].dateDeDebut)
                    ) {
                      date = formation.info.dates[i].jourDeFormation[0];
                      break;
                    } else {
                      date = "Prochainement";
                    }
                  }

                  if (date == "Prochainement") {
                    return (
                      <>
                        <Card
                          slug={formation.slug}
                          id={formation.id}
                          title={formation.title}
                          subTitle={formation.subTitle}
                          price={formation.info.price}
                          description={formation.description.description}
                          smallDescription={
                            formation.smallDescription.smallDescription
                          }
                          date={date}
                          key={formation.id}
                          image={image}
                          backgroundColor={
                            formation.color && formation.color.primary
                              ? formation.color.primary
                              : "#333"
                          }
                        />
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </section>
        </section>
      </Layout>
    </>
  );
};

export default Formations;

export const query = graphql`
  {
    allContentfulFormation {
      nodes {
        id
        title
        slug
        smallDescription {
          smallDescription
        }
        image {
          file {
            contentType
          }
          fixed(width: 368, height: 220) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        info {
          price
          discountedPrices
          discount
          diploma {
            json
          }
          location {
            json
          }
          dates {
            dateDeDebut
            dateDeFin
            jourDeFormation
            edition
          }
        }
        description {
          description
        }
        reviews {
          id
          content {
            json
          }
        }
        color {
          primary
          secondary
        }
        program {
          content {
            content
          }
          title
        }
      }
    }
  }
`;
